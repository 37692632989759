import { createTheme } from '@mui/material';
import { mergeWith } from 'lodash';
import { createContext, ReactElement, useEffect, useMemo } from 'react';
import { useAsync } from 'react-async-hook';
import {
  Loader,
  Notification,
  defaultTheme as UiKitDefaultTheme,
  darkTheme as UiKitDarkTheme,
} from 'react-ui-kit-exante';

import { useLocalStorage } from '../hooks/useLocalStorage';
import { getUrlWithHttps } from '../shared/helpers/apiHelpers';
import { useAppDispatch } from '../store/hooks';
import { theme as localTheme } from '../theme';
import { LIGHT_THEME, LOCAL_STORAGE_KEY_COLOR_SCHEME } from '../theme/const';
import { TColorScheme } from '../theme/types';

import { fetchConfig } from './api';
import { updateConfig } from './configSlice';
import { Config } from './types';

const initialState: Config = {
  apis: null,
  vars: {},
  modules: {},
  theme: {},
  menu: [],
  features: {},
  auth_flows: [],
  auth_data: {
    clientId: null,
  },
  logo: '',
};

export const ConfigContext = createContext<Config>(initialState);

type Props = {
  children: ReactElement;
};

export const ConfigProvider = ({ children }: Props): ReactElement | null => {
  const {
    error,
    loading,
    result = initialState,
  } = useAsync<Config>(fetchConfig, []);
  const dispatch = useAppDispatch();
  const { getItem } = useLocalStorage();
  const colorScheme =
    (getItem(LOCAL_STORAGE_KEY_COLOR_SCHEME) as TColorScheme) || LIGHT_THEME;
  const theme = result?.theme?.[colorScheme] || {};
  const UiKitTheme =
    colorScheme === LIGHT_THEME ? UiKitDefaultTheme : UiKitDarkTheme;

  const composedTheme = createTheme(
    mergeWith({}, UiKitTheme, localTheme, theme, (objValue, srcValue) => {
      if (typeof objValue === 'string' && srcValue === '') {
        return objValue;
      }

      return undefined;
    }),
  );

  useEffect(() => {
    dispatch(updateConfig(result));
  }, [result]);

  useEffect(() => {
    window.RECON_UI = {
      ...(window.RECON_UI || {}),
      RECON: result.apis?.RECON,
      api_urls: {
        RECON: result.apis?.RECON_API,
        RUNNER: result.apis?.RUNNER_API,
        RAW_DATA: result.apis?.RAW_DATA,
      },
      params: result.modules?.recon,
      theme: composedTheme,
    };
    window.REG_REPORTING_UI = {
      ...(window.REG_REPORTING_UI || {}),
      api_urls: {
        MIFIR: result.apis?.MIFIR,
        EMIR: result.apis?.EMIR,
        OFFSETS: result.apis?.OFFSETS,
      },
      theme: composedTheme,
    };
    window.CORPORATE_ACTIONS_UI = {
      ...(window.CORPORATE_ACTIONS_UI || {}),
      api_urls: {
        CORPORATE_ACTIONS_UI: result.apis?.CAS_API,
      },
      theme: composedTheme,
    };
    window.STOCK_LENDING_UI = {
      ...(window.STOCK_LENDING_UI || {}),
      api_urls: {
        SECURITY_LENDING: result.apis?.SECURITY_LENDING_API,
      },
      theme: composedTheme,
    };
    window.BRANDING_UI = {
      ...(window.BRANDING_UI || {}),
      api_urls: {
        BRANDING_UI: result.apis?.BRANDING_UI,
      },
      theme: composedTheme,
    };
    window.CRM_UI = {
      ...(window.CRM_UI || {}),
      api_urls: {
        CRM: result.apis?.CRM_API,
      },
    };
    window.CPRM_UI = {
      ...(window.CPRM_UI || {}),
      api_urls: {
        CPRM: result.apis?.CPRM_API,
      },
    };
    window.NOTIFICATIONS_SHAPER_UI = {
      ...(window.NOTIFICATIONS_SHAPER_UI || {}),
      api_urls: {
        NOTIFICATIONS_SHAPER: getUrlWithHttps(result.apis?.NS_API),
        CRM: getUrlWithHttps(result.apis?.CRM_API),
      },
      theme: composedTheme,
    };
    window.WORKFLOW_UI = {
      ...(window.WORKFLOW_UI || {}),
      api_urls: {
        BO: getUrlWithHttps(result.apis?.BO),
        AUTHDB: getUrlWithHttps(result.apis?.AUTHDB),
        WORKFLOW: getUrlWithHttps(result.apis?.WORKFLOW),
      },
      theme: composedTheme,
    };

    window.WEB_BO_UI = {
      ...(window.WEB_BO_UI || {}),
      api_urls: {
        BO: result.apis?.BO,
        SYMBOLBD_EDITOR: result.apis?.SYMBOLDB_EDITOR,
        WFE: result.apis?.WORKFLOW,
        AUDIT_LOG: result.apis?.AUDIT_LOG,
      },
      theme: composedTheme,
      features: result.features,
    };

    window.ORDER_MANAGEMENT_UI = {
      ...(window.ORDER_MANAGEMENT_UI || {}),
      api_urls: {
        BO: result.apis?.BO,
      },
      theme: composedTheme,
    };

    window.REMUS_UI = {
      ...(window.REMUS_UI || {}),
      api_urls: {
        REMUS: result.apis?.REMUS,
        AUTHDB: result.apis?.AUTHDB,
        LIQUIDATION_ESTIMATE: result.apis?.LIQUIDATION_ESTIMATE,
      },
      theme: composedTheme,
    };

    window.STRESS_TEST_UI = {
      ...(window.STRESS_TEST_UI || {}),
      api_urls: {
        RISK_API: result.apis?.RISK_API,
        NODE_BACK: result.apis?.NODE_BACK,
      },
      theme: composedTheme,
    };

    window.SYMBOLDB_UI_REACT = {
      ...(window.SYMBOLDB_UI_REACT || {}),
      api_urls: {
        SYMBOLDB_EDITOR: result.apis?.SYMBOLDB_EDITOR,
        STATIC_DATA: result.apis?.STATIC_DATA,
        BO: result.apis?.BO,
      },
      theme: composedTheme,
    };

    window.CHECKPOINT = {
      api_urls: {
        CHECKPOINT: result.apis?.CHECKPOINT,
      },
    };
    window.CRM_UI_REACT = {
      ...(window.CRM_UI_REACT || {}),
      api_urls: {
        CRM: result.apis?.CRM_API,
        BO: result.apis?.BO,
        AUDIT_LOG: result.apis?.AUDIT_LOG,
      },
      theme: composedTheme,
    };
    window.CPRM_UI_REACT = {
      ...(window.CPRM_UI_REACT || {}),
      api_urls: {
        CPRM: result.apis?.CPRM_API,
        BO: result.apis?.BO,
        AUDIT_LOG: result.apis?.AUDIT_LOG,
      },
      theme: composedTheme,
    };
    window.PARTNER_ADMIN_UI = {
      ...(window.PARTNER_ADMIN_UI || {}),
      api_urls: {
        BO: result.apis?.BO,
      },
      theme: composedTheme,
    };
  }, [result]);

  useEffect(() => {
    if (error) {
      Notification.error({
        title: error.message,
      });
    }
  }, [error]);

  const value = useMemo(() => ({ ...result, theme }), [result, theme]);

  if (loading) {
    return <Loader isCentered size="l" />;
  }

  if (error || !result) {
    return null;
  }

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};
